import SwabKit1x1 from '@public/dna_swab_kit_1x1.jpg';
import type { NextPageWithLayout } from 'next';

import {
  AlterPerformanceCouncil,
  BackedByExperts,
  EveryStep,
  FeaturedIn,
  HeroSection,
  HomepageFaq,
  HowAlterWorks,
  JourneyWithAlter,
  RealFitnessCoach,
} from '@/components/homepage';
import ShopLayout from '@/components/layouts/ShopLayout';
import StartJourneyCTA from '@/components/sections/StartJourneyCTA';
import type { ProductReviewsBottomLine } from '@/components/sections/YotpoReviews';
import {
  fetchReviewsMetadata,
  YotpoProductId,
  YotpoReviews,
} from '@/components/sections/YotpoReviews';
import GoogleProductMetadata from '@/components/shop/GoogleProductMetadata';
import {
  getAlterPerformanceCouncil,
  getCardRepeater,
  getGallery,
  getMedia,
  getText,
  getWhyAlterFAQ,
} from '@/graphql/contentful';
import { addApolloState, initializeApollo } from '@/lib/apollo/apollo-client';
import type { CardT, ExpertT, GalleryT, MediaT, TextT } from '@/utils/contentfulTypes';

type CardRepeaterT = {
  headline: string;
  itemCollection: {
    items: CardT[];
  };
};

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  const hero = await getMedia('uAJA9xKHM4bOPSKaUJvkp');

  const yotpoBottomLineResponse = await fetchReviewsMetadata(YotpoProductId.swabKit);

  const backedByExpertsFrom = await getGallery('WCXyxSxENS9UNgD9P9ITO');
  const featuredIn = await getGallery('6KBeIdx5b4lNEt42ih7zsW');

  const journeyWithAlter = await getCardRepeater('6Awq2ODdpmHdHlyoIb7ege');
  const howAlterWorks = await getCardRepeater('5tWiBYvMr0yXAbX8eXO9sd');
  const realFitnessCoach = await getCardRepeater('4kKj5BwfZyxo44d9oUDtFI');
  const everyStep = await getCardRepeater('2bAKqKBwSZmg3nbwirkqiY');

  const quote = await getText('V3tqNkmtxNWtjkmgJJKm4');
  const performanceCouncil = await getAlterPerformanceCouncil();

  // console.log(featuredIn.gallery.imagesCollection.items);

  const faq = await getWhyAlterFAQ();

  return addApolloState(apolloClient, {
    props: {
      hero,
      journeyWithAlter: journeyWithAlter.repeater,
      backedByExpertsFrom: backedByExpertsFrom,
      howAlterWorks: howAlterWorks.repeater,
      quote: quote,
      realFitnessCoach: realFitnessCoach.repeater,
      performanceCouncil: performanceCouncil.repeater?.itemCollection?.items,
      everyStep: everyStep.repeater,
      featuredIn: featuredIn,
      faq: faq,
      reviews: yotpoBottomLineResponse,
    },
    revalidate: 60, // 1 minutes
  });
}

interface HomepageProps {
  hero: MediaT;
  journeyWithAlter: CardRepeaterT;
  backedByExpertsFrom: GalleryT;
  howAlterWorks: CardRepeaterT;
  quote: TextT;
  realFitnessCoach: CardRepeaterT;
  performanceCouncil: ExpertT[];
  everyStep: CardRepeaterT;
  featuredIn: GalleryT;
  faq: TextT[];
  reviews: ProductReviewsBottomLine | null;
}

const Homepage: NextPageWithLayout<HomepageProps> = ({
  hero,
  journeyWithAlter,
  backedByExpertsFrom,
  howAlterWorks,
  quote,
  realFitnessCoach,
  performanceCouncil,
  everyStep,
  featuredIn,
  faq,
  reviews,
}) => {
  return (
    <div className="bg-white">
      {reviews && (
        <GoogleProductMetadata
          avgRating={reviews.average_score}
          totalRatings={reviews.total_reviews}
          productName="Alter DNA: Workout smarter, not harder"
          productDescription="Unlock your genetic traits that will help you reach your fitness goals with powerful new insights, and nutrition recommendations."
          productImages={[SwabKit1x1]}
        />
      )}
      <HeroSection data={hero} />
      <FeaturedIn headline={featuredIn.headline} images={featuredIn.imagesCollection.items} />
      <JourneyWithAlter
        headline={journeyWithAlter.headline}
        items={journeyWithAlter.itemCollection.items}
      />
      <BackedByExperts
        headline={backedByExpertsFrom.headline}
        items={backedByExpertsFrom.imagesCollection.items}
      />
      <HowAlterWorks
        headline={howAlterWorks.headline}
        items={howAlterWorks.itemCollection.items}
        quote={quote}
      />
      <RealFitnessCoach items={realFitnessCoach.itemCollection.items} />
      <div className="w-full h-8 bg-[#F6F6F1]" />
      <AlterPerformanceCouncil performanceCouncil={performanceCouncil} />
      <EveryStep headline={everyStep.headline} items={everyStep.itemCollection.items} />
      <YotpoReviews />
      <HomepageFaq items={faq} />
      <StartJourneyCTA variant="dna" />
    </div>
  );
};

Homepage.getLayout = (page) => {
  return (
    <>
      <ShopLayout fill dark hideBlurAtTop>
        {page}
      </ShopLayout>
    </>
  );
};

export default Homepage;
